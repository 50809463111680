@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.scroller {
    animation: 45s scroll infinite;
}

.scroller-xs {
    animation: 60s scroll infinite;
}

::-webkit-scrollbar {
    display: none;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
